<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar" help-page-component="LhdnInvoicesOverview"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-3 pl-5 pr-4>
            <v-layout row wrap>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-tabs
                        v-model="lhdnInvoicesOverviewCurrentTab"
                        color="success"
                        background-color="grey lighten-2"
                        slider-color="appic-green"
                    >
                        <v-tab key="invoices" v-show="$can('access','LhdnElectronicInvoice')" @click="updateKey('invoices')">
                            {{ 'e-' + $t('message.invoices') }}
                            <template v-if="pendingInvoices > 0">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div v-on="on">
                                            <v-badge
                                                v-bind="attrs"
                                                :content="pendingInvoices"
                                                :value="pendingInvoices"
                                                class="mb-2"
                                                color="red"
                                                overlap
                                            ></v-badge>
                                        </div>
                                    </template>
                                    <span>{{ pendingInvoices + " " + $t('message.invoicesForVerification') }}</span>
                                </v-tooltip>
                            </template>
                        </v-tab>
                        <v-tab key="debit-notes" v-show="$can('access','LhdnElectronicInvoice')" @click="updateKey('debit-notes')">
                            {{ 'e-DN' }}
                            <template v-if="pendingDebitNotes > 0">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div v-on="on">
                                            <v-badge
                                                v-bind="attrs"
                                                :content="pendingDebitNotes"
                                                :value="pendingDebitNotes"
                                                class="mb-2"
                                                color="red"
                                                overlap
                                            ></v-badge>
                                        </div>
                                    </template>
                                    <span>{{ pendingDebitNotes + " " + $t('message.debitNotesForVerification') }}</span>
                                </v-tooltip>
                            </template>
                        </v-tab>
                        <v-tab key="credit-notes" v-show="$can('access','LhdnElectronicInvoice')" @click="updateKey('credit-notes')">
                            {{ 'e-CN' }}
                            <template v-if="pendingCreditNotes > 0">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div v-on="on">
                                            <v-badge
                                                v-bind="attrs"
                                                :content="pendingCreditNotes"
                                                :value="pendingCreditNotes"
                                                class="mb-2"
                                                color="red"
                                                overlap
                                            ></v-badge>
                                        </div>
                                    </template>
                                    <span>{{ pendingCreditNotes + " " + $t('message.creditNotesForVerification') }}</span>
                                </v-tooltip>
                            </template>
                        </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="lhdnInvoicesOverviewCurrentTab">
                        <v-tab-item key="invoices-list" v-show="$can('access','LhdnElectronicInvoice')">
                            <v-card flat>
                                <LhdnInvoices :key="invoiceListKey" @update-done="resetPendingNumbers"></LhdnInvoices>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="debit-notes-list" v-show="$can('access','LhdnElectronicInvoice')">
                            <v-card flat>
                                <LhdnDebitNotes :key="debitNoteListKey" @update-done="resetPendingNumbers"></LhdnDebitNotes>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="credit-notes-list" v-show="$can('access','LhdnElectronicInvoice')">
                            <v-card flat>
                                <LhdnCreditNotes :key="creditNoteListKey" @update-done="resetPendingNumbers"></LhdnCreditNotes>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import LoadingComponent from "Components/Appic/LoadingComponent";
import {api} from "Api";

const LhdnInvoices = () => ({
    component: import("Components/Appic/Lhdn/LhdnInvoices"),
    loading: LoadingComponent
});

const LhdnDebitNotes = () => ({
    component: import("Components/Appic/Lhdn/LhdnDebitNotes"),
    loading: LoadingComponent
});

const LhdnCreditNotes = () => ({
    component: import("Components/Appic/Lhdn/LhdnCreditNotes"),
    loading: LoadingComponent
});

export default {
    name: "LhdnInvoicesOverview",
    components: {LhdnInvoices, LhdnCreditNotes, LhdnDebitNotes},
    data() {
        return {
            invoiceListKey: 100,
            debitNoteListKey: 200,
            creditNoteListKey: 300,
            loader: false,
            pendingInvoices: 0,
            pendingCreditNotes: 0,
            pendingDebitNotes: 0,
        }
    },
    computed: {
        ...mapFields('runtime',{
            lhdnInvoicesOverviewCurrentTab: 'lhdnInvoicesOverviewCurrentTab'
        })
    },
    methods: {
        resetPendingNumbers() {
            api
                .get('/lhdn/pending-documents-count')
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.pendingInvoices = response.data.data.invoices
                        this.pendingCreditNotes = response.data.data.credit_notes
                        this.pendingDebitNotes = response.data.data.debit_notes
                    }
                })
                .catch(() => {
                    console.log('Cannot load pending document count')
                })
        },
        updateKey(clickedTab) {
            switch(clickedTab) {
                case 'invoices':
                    this.invoiceListKey ++
                    break;
                case 'debit-notes':
                    this.debitNoteListKey ++
                    break;
                case 'credit-notes':
                    this.creditNoteListKey ++
                    break;
            }
        }
    },
    mounted(){
        this.$title = this.$t('message.titles.lhdnInvoices')
        this.resetPendingNumbers()
    }
}
</script>

<style scoped>

</style>