var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('appic-page-title-bar',{ref:"breadcrumbBar",attrs:{"help-page-component":"LhdnInvoicesOverview"}}),_c('app-section-loader',{attrs:{"status":_vm.loader}}),_c('v-container',{attrs:{"fluid":"","grid-list-xl":"","pb-0":"","pt-3":"","pl-5":"","pr-4":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('app-card',{attrs:{"fullBlock":true,"colClasses":"xl12 lg12 md12 sm12 xs12"}},[_c('v-tabs',{attrs:{"color":"success","background-color":"grey lighten-2","slider-color":"appic-green"},model:{value:(_vm.lhdnInvoicesOverviewCurrentTab),callback:function ($$v) {_vm.lhdnInvoicesOverviewCurrentTab=$$v},expression:"lhdnInvoicesOverviewCurrentTab"}},[_c('v-tab',{directives:[{name:"show",rawName:"v-show",value:(_vm.$can('access','LhdnElectronicInvoice')),expression:"$can('access','LhdnElectronicInvoice')"}],key:"invoices",on:{"click":function($event){return _vm.updateKey('invoices')}}},[_vm._v(" "+_vm._s('e-' + _vm.$t('message.invoices'))+" "),(_vm.pendingInvoices > 0)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({},on),[_c('v-badge',_vm._b({staticClass:"mb-2",attrs:{"content":_vm.pendingInvoices,"value":_vm.pendingInvoices,"color":"red","overlap":""}},'v-badge',attrs,false))],1)]}}],null,false,743415801)},[_c('span',[_vm._v(_vm._s(_vm.pendingInvoices + " " + _vm.$t('message.invoicesForVerification')))])])]:_vm._e()],2),_c('v-tab',{directives:[{name:"show",rawName:"v-show",value:(_vm.$can('access','LhdnElectronicInvoice')),expression:"$can('access','LhdnElectronicInvoice')"}],key:"debit-notes",on:{"click":function($event){return _vm.updateKey('debit-notes')}}},[_vm._v(" "+_vm._s('e-DN')+" "),(_vm.pendingDebitNotes > 0)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({},on),[_c('v-badge',_vm._b({staticClass:"mb-2",attrs:{"content":_vm.pendingDebitNotes,"value":_vm.pendingDebitNotes,"color":"red","overlap":""}},'v-badge',attrs,false))],1)]}}],null,false,1316388537)},[_c('span',[_vm._v(_vm._s(_vm.pendingDebitNotes + " " + _vm.$t('message.debitNotesForVerification')))])])]:_vm._e()],2),_c('v-tab',{directives:[{name:"show",rawName:"v-show",value:(_vm.$can('access','LhdnElectronicInvoice')),expression:"$can('access','LhdnElectronicInvoice')"}],key:"credit-notes",on:{"click":function($event){return _vm.updateKey('credit-notes')}}},[_vm._v(" "+_vm._s('e-CN')+" "),(_vm.pendingCreditNotes > 0)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({},on),[_c('v-badge',_vm._b({staticClass:"mb-2",attrs:{"content":_vm.pendingCreditNotes,"value":_vm.pendingCreditNotes,"color":"red","overlap":""}},'v-badge',attrs,false))],1)]}}],null,false,3684362873)},[_c('span',[_vm._v(_vm._s(_vm.pendingCreditNotes + " " + _vm.$t('message.creditNotesForVerification')))])])]:_vm._e()],2)],1),_c('v-tabs-items',{model:{value:(_vm.lhdnInvoicesOverviewCurrentTab),callback:function ($$v) {_vm.lhdnInvoicesOverviewCurrentTab=$$v},expression:"lhdnInvoicesOverviewCurrentTab"}},[_c('v-tab-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.$can('access','LhdnElectronicInvoice')),expression:"$can('access','LhdnElectronicInvoice')"}],key:"invoices-list"},[_c('v-card',{attrs:{"flat":""}},[_c('LhdnInvoices',{key:_vm.invoiceListKey,on:{"update-done":_vm.resetPendingNumbers}})],1)],1),_c('v-tab-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.$can('access','LhdnElectronicInvoice')),expression:"$can('access','LhdnElectronicInvoice')"}],key:"debit-notes-list"},[_c('v-card',{attrs:{"flat":""}},[_c('LhdnDebitNotes',{key:_vm.debitNoteListKey,on:{"update-done":_vm.resetPendingNumbers}})],1)],1),_c('v-tab-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.$can('access','LhdnElectronicInvoice')),expression:"$can('access','LhdnElectronicInvoice')"}],key:"credit-notes-list"},[_c('v-card',{attrs:{"flat":""}},[_c('LhdnCreditNotes',{key:_vm.creditNoteListKey,on:{"update-done":_vm.resetPendingNumbers}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }